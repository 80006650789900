import React, { forwardRef, useEffect, useImperativeHandle, useRef } from "react"
import CardIcons from "../../../../images/Icons/card-icons.svg"
import CardIcon from "../../../../images/Icons/card-icon.svg"
import CheckIcon from "../../../../images/Icons/check-icon.svg"
import * as styles from "../paymentTypes.module.scss"

import { CardNumberElement, CardExpiryElement, CardCvcElement, useElements } from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from "react-redux"
import { storeFormDataAction } from "../../../../store/actions"

const Card = ({ active, hasError }) => {

    const paymentData = useSelector(state => state?.checkout.payment)
    const dispatch = useDispatch();


    const CARD_ELEMENT_OPTIONS = {
        style: {
            base: {
                color: "#32325d",
                fontFamily: 'inherit',
                fontSmoothing: "antialiased",
                fontSize: "18px",
                "::placeholder": {
                    color: "#aab7c4",
                },
            },
            invalid: {
                color: "#FA755A",
                iconColor: "#FA755A",
            },
        },
        classes: {
            // base: styles.input,
            complete: styles.input,
            empty: styles.input,
            focus: styles.input,
            invalid: styles.input,
            webkitAutofill: styles.input,
        },
        hidePostalCode: true,
    };

    const elements = useElements();

    const handleChange = (name, value) => {

        let _data = paymentData;
        _data[name] = value;

        console.log("CARD CHANGE", name, value)

        // dispatch(storeFormDataAction({ payment: _data }));
    }

    useEffect(() => {

        if (!elements) {
            console.log("NO ELEMENTS")
            return;
        }

        const elementNumber = elements.getElement(CardNumberElement);
        const elementExpiry = elements.getElement(CardExpiryElement);
        const elementCvC = elements.getElement(CardCvcElement);



        if (elementNumber !== null) {

            elementNumber.on('change', function (event) {
                handleChange('creditCardNumber', event)
            });

            elementExpiry.on('change', function (event) {

                handleChange('creditCardExpiry', event.value)

            });

            elementCvC.on('change', function (event) {

                handleChange('creditCardCVC', event.value)

            });
        }


    })

    return (
        <div
            className={`${styles.item} ${active && styles.active}`}
            onClick={() => { }}
        >
            <div className={styles.btn}>
                <CardIcon />
                Kreditkarte
                {active && <CheckIcon className={styles.activeIcon} />}
            </div>
            {active && (
                <div>
                    <p
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        Per Kreditkarte bezahlen.
                        <CardIcons />
                    </p>
                    <div style={{ maxWidth: 500 }}>
                        <div>
                            <br />Kartennummer *<br /><br />
                            <div>
                                <CardNumberElement options={CARD_ELEMENT_OPTIONS} />
                                {/* <CardIcon style={{ width: 25 }} /> */}
                            </div>
                        </div>
                        <div className="container fluid" style={{ padding: 0, marginTop: 20 }}>
                            <div className="col6">
                                <div>
                                    <br />Gültig bis *<br /><br />
                                    <CardExpiryElement options={CARD_ELEMENT_OPTIONS} />
                                </div>
                            </div>
                            <div className="col6">
                                <div>
                                    <br />Sicherheitscode (CVC) *<br /><br />
                                    <CardCvcElement options={CARD_ELEMENT_OPTIONS} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Card;

export const Card_Icon = CardIcon;
export const Check_Icon = CheckIcon;
